import React from "react";
import "../styles//Navbar.css";
import {introItems} from "./Constants.js"


export default function Navbar(props) {


    return (
        <div className = {"Navbar " + (props.showMenu ? "NavbarShowMenu" : "")}>
            <div className = {"InitialBoxContainer " + (props.showMenu === true ? "InitialBoxContainerNoDisplay" : "")}>
                <div className = "InitialBox">
                    <div className = "Initials">RHS</div>
                </div>
            </div>
            <div className = {"NavItemsContainer " + (props.showMenu === true ? "NavItemsContainerNoDisplay" : "")}>
                <div className = {"NavItems " + (props.smlWindow === true ? "NavItemsSml" : "")}>
                    {introItems.map((item, index) => (
                        <div key={index} className="NavItem" onClick={() => props.scrollToRef(props.refsArray[index])}>
                            <div className = "NavText">{item.title}</div>
                        </div>
                    ))}
                </div>
                <div className = {"NavItemsSmlIcon " + (props.smlWindow === true ? "NavItemsSmlIconSml" : "")} onClick={props.onClick}>
                    <div className = "NavIcon">|||</div>
                </div>
            </div>
            <div className = {"NavMenuNoDisplay " + (props.showMenu === true ? "NavMenu" : "")}>
                    <div className = {"xMark"}>
                        <div className = "xMarkIcon" onClick={props.onClick}>X</div>
                    </div>
                    <div className = {"NavMenuItems " + (props.smlWindow === true ? "NavMenuItemsSml" : "")}>
                        {introItems.map((item, index) => (
                            <div key={index} className="NavMenuItem" onClick={() => {
                                props.onClick(); // First function call
                                setTimeout(() => {
                                    props.scrollToRef(props.refsArray[index]); // Second function call with delay
                                  }, 100); 
                            }}>
                                <div className = "NavMenuText">{item.title}</div>
                            </div>
                        ))}
                    </div>
            </div>
        </div>
    )


}

