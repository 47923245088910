import React, { forwardRef } from "react";
import {TimelineData} from "./Constants.js"
import '../styles/Timeline.css';



const Timeline = forwardRef((props, timelineRef) => {

  const [clickedId, setClickedId] = React.useState(null); // Used to identify which circle and/or date has been clicked on
  const handleClick = (index) => {
    clickedId == null ? setClickedId(index) : setClickedId(null);

  }

  console.log(clickedId)

  return (
    <div data-aos="fade-up" className={"Timeline " + (props.showMenu === true ? "TimelineNoDisplay" : "")} ref={timelineRef}>

      <div className="timelineNameTop">
        <div className={"timelineNameTxt " + (props.smlWindow ? "timelineNameTxtSml" : "")}>
        TIMELINE
        </div>
      </div>
        <div className="tComponents" >
          {TimelineData.map((item, index) => {
            return (
              <div className={"tComponent " + (clickedId == item.id ? "tComponentClk " : "") + (props.smlWindow ? "tComponentSml " : "")} key={item.id} onClick={() => handleClick(item.id)} >
                  <div className={"tComponentTop " + (clickedId == item.id ? "tComponentTopClk" : "")}>
                    <img src={item.img} alt={item.alt} ></img>
                    <div className={"tComponentDesc " + (clickedId == item.id ? "tComponentDescClk " : "") + (props.smlWindow ? "tComponentDescSml " : "")}>
                  
                      {item.info}
                    </div>
                  </div>
                  <div className="tComponentBottom">
                    <div className="tTitle">
                      {item.title}
                      
                    </div>
                    <div className="tDate">
                      {item.date}
                    </div>
                  </div>
              </div>
            )
          })}

        </div>
        

    </div>
  );
});

export default Timeline;