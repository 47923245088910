import Navbar from './components/Navbar';
import Intro from './components/Intro';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import Timeline from './components/Timeline';
import './App.css';
import React, { useRef, useEffect } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";


function App() {

  const widthSwitch = 800;
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [smlWindow, setSmlWindow] = React.useState(window.innerWidth <= widthSwitch);
  const [showMenu, setShowMenu] = React.useState(false);

  const handleClick = () => {
    setShowMenu(!showMenu);
  }
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setSmlWindow(window.innerWidth <= widthSwitch);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize',handleResize);
    };
  }, []);
    
  const aboutMeRef = useRef();
  const timelineRef = useRef();
  const projectRef = useRef();
  const refsArray = [aboutMeRef, timelineRef, projectRef]; // Array of refs

  
  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect (() => {
    Aos.init({duration: 2000});
    }, []);

  return (
    <div className="App">
      <Navbar smlWindow={smlWindow} showMenu={showMenu} onClick={handleClick}
        scrollToRef={scrollToRef}
        refsArray={refsArray}
      />
      <Intro
        widthSwitch={widthSwitch}
        smlWindow={smlWindow}
        scrollToRef={scrollToRef}
        aboutMeRef={aboutMeRef}
        timelineRef={timelineRef}
        projectRef={projectRef}
        showMenu={showMenu}
      />
      <AboutMe smlWindow={smlWindow} ref={aboutMeRef} showMenu={showMenu}/>
      <Timeline smlWindow={smlWindow} ref={timelineRef} showMenu={showMenu}/>
      <Projects smlWindow={smlWindow} ref={projectRef} showMenu={showMenu}/>
    </div>
  );
}

export default App;
