import rsPhoto from '../images/headshot.jpg';
import '../styles/AboutMe.css';
import { forwardRef } from 'react';

const AboutMe = forwardRef((props, aboutMeRef) => {


    return (
      <div className={"AboutMe" + (props.smlWindow ? " AboutMeSml" : "") + (props.showMenu === true ? " AboutMeNoDisplay" : "")} ref={aboutMeRef}>
        <div className={"aboutMeHoriz " + (props.smlWindow ? "aboutMeHorizSml" : "")}>
          <div className={"aboutMeLeft " + (props.smlWindow ? "aboutMeLeftSml" : "")}>
            <div className={"aboutMeName " + (props.smlWindow ? "aboutMeNameSml" : "")}>
              <div className={"aboutMeNameTxt " + (props.smlWindow ? "aboutMeNameTxtSml" : "")}>
                Hi, I'm Ricky.
              </div>
          </div>
          <div className={"aboutMeP " + (props.smlWindow ? "aboutMePSml" : "")}>
            <p className={"aboutMePTxt " + (props.smlWindow ? "aboutMePTxtSml" : "")}>
            My professional endeavors exist at the intersection of environmental engineering and digital consulting. I hold a BS in Environmental Engineering from the University of Georgia and an MS in Environmental Engineering from the University of North Carolina, Chapel Hill. I am currently employed as a carbon accounting associate at <a href="https://www.greenprojecttech.com" rel="noopener">Green Project Technologies</a>.
            </p>
          </div>
        </div>
        <div className={"aboutMeRight " + (props.smlWindow ? "aboutMeRightSml" : "")} >
          <div className={"imgContainer " + (props.smlWindow ? "imgContainerSml" : "")}>
            <img className={"headshot " + (props.smlWindow ? "headshotSml ": "")}
                src={rsPhoto}
                alt="RHS"
                />
            
          </div>
        </div>
      </div>
    </div>
    );
  });

  export default AboutMe;