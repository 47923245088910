import '../styles/Intro.css';

export default function Intro(props) {
  const { showMenu, smlWindow } = props;

  const introClass = `Intro ${showMenu ? "IntroNoDisplay" : ""}`;
  const nameClass = `name ${smlWindow ? "nameSml" : ""}`;
  const nameTxtClass = `nameTxt ${smlWindow ? "nameTxtSml" : ""}`;
  const siteNavContainerClass = `siteNavContainer ${smlWindow ? "siteNavContainerSml" : ""}`;
  const siteNavClass = `siteNav ${smlWindow ? "siteNavSml" : ""}`;

  return (
    <div className={introClass}>
      <div className="introHoriz">
        <div className={nameClass}>
          <div className={nameTxtClass}>
            Ricky <br />
            Strott
          </div>
        </div>
        <div className={siteNavContainerClass}>
          <ul className={siteNavClass}>
            <li>Environmental Engineer</li>
            <li>Sustainable Consultant</li>
            <li>Digital Enthusiast</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
