export const introItems = [
    {
      id: "aboutMe",
      title: "ABOUT ME",
      destination: "AboutMe"
    },
    {
      id: "timeline",
      title: "TIMELINE",
      destination: "Timeline"
    },
    {
      id: "projects",
      title: "PROJECTS",
      destination: "Projects"
    },
  ];

export const aboutMeParagraphs = [
    {id: "aboutMeP",
      info: ["Ricky’s professional endeavors exist at the intersection of environmental engineering and digital consulting. He holds a BS in Environmental Engineering from the University of Georgia and an MS in Environmental Engineering from the University of North Carolina, Chapel Hill. Ricky first developed an interest in coding as a master’s student at UNC Chapel Hill, where he used Matlab for his research. He has since expanded his programming skills, gaining experience with Python, VBA, and SQL while working on a variety of projects.",
            "Ricky eventually joined Environmental Resources Management (ERM) as a digital consultant. At ERM, he supports the configuring of air quality and water systems in Enablon, an integrated software solution used for Risk and Compliance, Engineering and Operations, and EHSQ and Sustainability.",]}
  ]

export const projectData = [
  {id: 0,
    title: "North Carolina Wastewater Pathogen Research Surveillance Network",
    img: "/images/WW.jpg",
    alt: "Waste water treatment plant",
    info: "Using MATLAB and ArcGIS Pro, collaborated with a team of university of state researchers to predict COVID-19 incidence rates; Conducted disease mapping using Bayesian Maximum Entropy method of geostatistics.",
    // fade: "fade-right"
  },
  {id: 1,
    title: "Space/Time Exposure Mapping of Volatile Organic Compounds",
    img: "/images/gulfOfMexico.jpg",
    alt: "Gulf of Mexico beach",
    info: "Using MATLAB and ArcGIS Pro, modeled atmospheric concentration of Benzene, Toluene, Ethylbenzene, and Xylene (BTEX) + Styrene in the Gulf region of the United States; Uncovered autocorrelation of VOCs over space and time to predict exposure risk.",
    // fade: "fade-left"
  },
    {id: 2,
    title: "EPA Region IX Exceptional Events Support",
    img: "/images/RegIXEE.jpg",
    alt: "Wildire and road running through it.",
    info: "Used Python to successfully obtain five years of air quality data via REST API requests and cleaned the data. Additionally, used Python (geopandas) to plot wildfire and atmospheric smoke GIS data for visual support. Lastly, presented future improvements to display GIS maps on interactive dashboards (plotly/dash).",
    // fade: "fade-up-right"
  },
  {id: 3,
    title: "Greenhouse Gas Mandatory Reporting Rule Database Support",
    img: "/images/GHGRP.jpg",
    alt: "Smokestack of industrial plant",
    info: "Using SQL, successfully generated over thirty-five new dataviews in Oracle and used Microsoft VBA to automate export of dataviews on daily basis for use by RTI staff supporting GHGRP technical directives.",
    // fade: "fade-up-left"
  },
  {id: 4,
    title: "EPA Updated TANKS Web Application Support",
    img: "/images/tanks.jpg",
    alt: "Out of ground distillate storage tank",
    info: "Supported beginning development phases of an updated web application to serve as update to EPA's current TANKS 4.09d software. Used React, JavaScript, and HTML to develop interface and calucations based on AP-42 Chapter 7.",
    // fade: "fade-left"
  },
  {id: 5,
    title: "Enablon Configuration for Chemical Manufacturing Client",
    img: "/images/chem.jpg",
    alt: "Viscous substance with bubbles",
    info: "Reviewed client's air quality emissions calculations and configured equations in Enablon. Assisted with Enablon back-end configuration to configure data for state and federal reporting requirements.",
    // fade: "fade-up"
  },
  {id: 6,
  title: "Regression Analysis to Predict Utility Consumption Using Temperature Data",
  img: "/images/hvac-min.jpg",
  alt: "Black and white HVAC system",
  info: "Developed Python script to predict utility consumption (electricity and natural gas) data for clients with a regression analysis utilitizing heating degree days and cooling degree days across international location."}
]


export const TimelineData = [
  {id: 0, 
    title: "B.S. Environmental Engineering",
    date: "May, 2019",
    info: "Graduated from the University of Georgia with a Bachelor of Science in Environmental Engineering.",
    alt: "University of Georgia arches",
    img: "/TimelinePhotos/UGA_Arches.jpg"},
  {id: 1,
    title: "NIOSH Traineeship Recipient",
    date: "January, 2020",
    info: "Recipient of National Institute for Occupational Safety and Health (NIOSH) Traineeship to pursure research related to occupational and environmental exposures.",
    alt: "Oil mixed with water",
    img: "/TimelinePhotos/NIOSHstock.jpg"},
  {id: 2,
    title: "Teaching / Research Assistantship",
    date: "August, 2020",
    info: "Began working as both teaching assistant (course: Advanced Functions of Temporal GIS) and research assistant (Bayesian Maximum Entropy geostatistical modeling).",
    alt: "Geospatial contamination heat map of southeastern United States.",
    img: "/TimelinePhotos/BTEX.png"},
  {id: 3,
    title: "Engineer-in-Training (EIT) Certification",
    date: "December, 2020",
    info: "Obtained Engineer-in-Training (EIT) certification from via National Council of Examiners for Engineering and Surveying.",
    alt: "University of Chapel Hill Bell Tower",
    img: "/TimelinePhotos/UNC_ChapelHill.jpg"},
  {id: 4,
    title: "George C. Bunker Award (UNC) Recipient",
    date: "May, 2021",
    info: "Recipient of George C. Bunker Award for outstanding scholarship and professional promise in Environmental Engineering withing UNC-Chapel Hill's Department of Environmental Sciences and Engineering.",
    alt: "Wastewater treatment plant",
    img: "/TimelinePhotos/SanitationEng.jpg"},
  {id: 5,
    title: "M.S. Env. Engineering/RTI International",
    date: "August, 2021",
    info: "Graduated from the University of North Carolina - Chapel Hill (UNC) with a Master of Science in Environmental Engineering, and subsequently began working at RTI International as an Air Quality Engineering Consultant. Work primarily involved supporting EPA's Greenhouse Gas Mandatory Reporting Rule (GHGRP), supporting New Source Performance Standards (NSPS) programs, and contributing to various technical report related to electricity generating units (EGU's).",
    alt: "RTI International Campus Building",
    img: "/TimelinePhotos/RTI.jpeg"},
  {id: 6,
    title: "ERM",
    date: "February, 2023",
    info: "Began working at ERM as a Digital Consultant. Work primarily involves supporting clients with Enablon configuration and data management.",
    alt: "Green valley inside mountaneous terrain",
    img: "/TimelinePhotos/Mountains.jpg"},
  {id: 7,
    title: "Green Project Technologies",
    date: "November, 2023",
    info: "Began working at Green Project Technologies as a carbon account associate. Work primarily involves supporting clients with carbon accounting and data management.",
    alt: "Yosemite National Park",
    img: "/TimelinePhotos/yosemite-small.jpg"},
];
