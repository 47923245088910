import {projectData} from './Constants.js';
import "../styles/Projects.css";
import React, { forwardRef } from "react";

const Projects = forwardRef((props, projectRef) => {
  const [projectTitle, setProjectTitle] = React.useState(true);
  const [clickedId, setClickedId] = React.useState(null);
  const [isHovered, setIsHovered] = React.useState(null);

  const handleClick = () => {
    setProjectTitle(prev => !prev);
  };
  return (
      <div data-aos="fade-up" className={"Projects " + (props.showMenu === true ? "ProjectsNoDisplay" : "")} ref={projectRef}>
        <div className={"projectsTitleHoriz " + (props.smlWindow ? "projectsTitleHorizSml" : "")}>
          <div className="projectsTitle">
            PROJECTS
          </div>
        </div>
        <div className="projectsHoriz">
          <div className="projects">
            <div className={"projectsContain " + (props.smlWindow ? "projectsContainSml" : "")}>
            {projectData.map((project) => {
              return (
                // <a href="#" className="projectA" key= {project.id} onClick={handleClick}>
                <div data-aos={project.fade} className={"project" + (clickedId == project.id ? " projectClk" : "") + (props.smlWindow ? " projectSml" : "") + ((props.smlWindow && clickedId == project.id) ? " projectClkSml" : "")} key={project.id} 
                      // onClick={handleClick}
                      onClick={() => {
                        (clickedId === project.id) ? setClickedId(null) : setClickedId(project.id)
                      }   
                  }
                  onMouseEnter={() => setIsHovered(project.id)}
                  onMouseLeave={() => setIsHovered(null)}>
                  <div className="projectTitleContainer">
                    {(clickedId != project.id) && 
                    <div className={"projectTitle" + (props.smlWindow ? " projectTitleSml" : "")}>
                      {project.title}
                    </div>}
                  </div>
                  {(clickedId === project.id) &&
                    <div className="projectDescription">
                      {project.info}
                    </div> 
                  }
                  <img alt={project.alt} src={project.img} alt={project.title} height="500px" className={"projectImg" + (isHovered === project.id ? ' projectImgHov' : '') + (clickedId == project.id ? " projectImgClk" : "")}/>
                  <div className={"imgBackground " + (clickedId == project.id ? "imgBackgroundClk": "")}></div>
                </div>
                
              );
            })}
            </div>
          </div>
        </div>
      </div>

    );
  });

  export default Projects;